// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/*Hide the GraphiQL execute button only if one of its parents has the hide-execute-button class */
.hide-execute-button .graphiql-execute-button {
  display: none;
}


/*
Hide the response window only if one if its parents has the hide-execute-button class
.graphiql-response ~ :not(.graphiql-response)
  selects the parent of any div with the graphiql-response class
*/
.hide-execute-button div:has(> .graphiql-response) {
  display: none !important; /* Important is necessary because the div element we are trying to select explicitly has its display set to flex*/
}


/*
Until either https://github.com/grafana/grafana/issues/85044 is fixed or https://github.com/graphql/graphiql/issues/3565
is fixed, we need to disable pointer events for any info tooltips that have "javascript:void 0" as their href.
*/
/*.CodeMirror-info a {*/
/*  pointer-events: none;*/
/*}*/
`, "",{"version":3,"sources":["webpack://./components/modify_graphiql.css"],"names":[],"mappings":";;AAEA,iGAAiG;AACjG;EACE,aAAa;AACf;;;AAGA;;;;CAIC;AACD;EACE,wBAAwB,EAAE,iHAAiH;AAC7I;;;AAGA;;;CAGC;AACD,uBAAuB;AACvB,0BAA0B;AAC1B,IAAI","sourcesContent":["\n\n/*Hide the GraphiQL execute button only if one of its parents has the hide-execute-button class */\n.hide-execute-button .graphiql-execute-button {\n  display: none;\n}\n\n\n/*\nHide the response window only if one if its parents has the hide-execute-button class\n.graphiql-response ~ :not(.graphiql-response)\n  selects the parent of any div with the graphiql-response class\n*/\n.hide-execute-button div:has(> .graphiql-response) {\n  display: none !important; /* Important is necessary because the div element we are trying to select explicitly has its display set to flex*/\n}\n\n\n/*\nUntil either https://github.com/grafana/grafana/issues/85044 is fixed or https://github.com/graphql/graphiql/issues/3565\nis fixed, we need to disable pointer events for any info tooltips that have \"javascript:void 0\" as their href.\n*/\n/*.CodeMirror-info a {*/\n/*  pointer-events: none;*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
